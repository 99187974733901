import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import TableDataRenderer from "../components/TableData";
const homeQuery = graphql`
  query HomeQuery {
    facetBy(key: TAG) {
      field
      fieldValue
      countries
      events
      views
      platforms
      tags
      vendors
      uniques
      last_updated
    }
  }
`;

export default function HomePage(): React.ReactElement {
  return (
    <Layout>
      <StaticQuery query={homeQuery} render={(data) => <TableDataRenderer data={data} />} />
    </Layout>
  );
}
